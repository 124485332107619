footer {
    display: flex;
    background-color: #123E70;
    height: 420px;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
}

.info-footer {
    margin-left: 4%;
    /* padding-left: 10%; */
}

.info-footer,
.forClient,
.Horaires {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 45%;
    height: 370px;
    border: #123E70 3px solid;
}

.logo-footer {
    margin: 0 !important;
}

.text-info-footer {
    color: #F1F6F5;
    font-size: 20px;
    width: 377px;
    /* margin-left: 20px; */
}

.title-client {
    flex-direction: column;
    align-items: center;
    display: flex;
}

.cosial-icon {
    background-color: #F1F6F5;
    width: 318px;
    height: 70px;
    border-radius: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.grid-footer{
    display: flex;
    justify-content: space-around;
}
.forClient,
.Horaires {
    background-color: #F1F6F5;
    border-radius: 20px;
}

.forClient .footer-info {
    display: grid;
    grid-template-columns: 20% 70%;
}

.icon-address {
    margin: 0 20px;
    border-radius: 100px;
}

.img-icon-client {
    width: 110px;
    height: 110px;
}

.icon-address {
    width: 30px;
    height: 30px;
}

.text-horaires {
    border-top: #123E70 2px solid;
    font-size: 18px;
    display: grid;
    height: 30px;
    padding-left: 20px;
    align-items: center;
}