.bar-1 {
    text-align: center;
    font-size: x-large;
    background-color: #123E70;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.bar-1 p {
    color: #F1F6F5;
    text-align: center;
    padding: 1.5% 0;
}
.bar-1 img {
    margin: 15px 30px;
}

#services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    width: 80%;
    margin: auto;
}

.block-service {
    width: 250px;
    height: 450px;
    /* border: 1px solid #123E70; */
    box-shadow: 0px 3px 15px 1px #123e7064;
    background-color: #F1F6F5;
    margin: 28px;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    padding: 0 22px;
    flex-direction: column;
}

.icon-service{
    background-color: #123E70;
    border-radius: 100px;
    border: 4px solid #F1F6F5;
    box-shadow: #123E70 0px 0px 4px;
    width: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    line-height: 11;
}
.icon-service img{
    width: 70%;
}
p.desc-service {
    font-size: 24px;
    color: #000000;
}

.btn-show-me{
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.block-service a {
    background-color: #123E70;
    color: #F1F6F5;
    width: 160px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 4px solid #F1F6F5;
    font-size: 20px;
    font-weight: 550;
    box-shadow: #123E70 0px 0px 4px;
}

.block-service:hover  {
    background-color: #8b92cec2;
}

.title-services{
    display: flex;
    width: 100%;
    justify-content: center;
}

.title-services h1{
    font-size: 72px;
    margin-top: 30px;
}