.bar-2 {
    text-align: center;
    font-size: x-large;
    background-color: #123E70;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
    padding-left: 110px;
    border-radius: 5px;
}

.bar-2 p,
.bar-2 h3 {
    color: #F1F6F5;
    text-align: center;
    margin: 10px 0px;
}

.bar-2 img {
    background-color: #F1F6F5;
    border-radius: 8px;
}

.bar-2 a {
    background-color: #F1F6F5;
    font-size: 22px;
    font-weight: 600;
    height: 62px;
    display: flex;
    width: 330px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    border: #123E70 1px solid;
    box-shadow: 0px 0px 2px #f1f6f5;
}

.bar-2 a:hover {
    background-color: #123E70;
    color: #F1F6F5;
    border: #123E70 3px solid;
    box-shadow: 0px 0px 5px 3px #f1f6f5;
}

.bar-2 img {
    margin: 15px 30px;
}