
.top-nav {
    background-color: #123E70;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #000000;
    filter: drop-shadow(0px 0px 4px #000000);
    position: fixed;
    top: 0;
    width: 100%;
    border-radius: 5px;
    max-width: 1920px;
    overflow: hidden;
    z-index: 100;
}

.logo-nav {
    margin: 0 5%;
}

.logo-nav a {
    background-color: #F1F6F5;
    width: 302px;
    height: 50px;
    display: flex;
    text-align: center;
    justify-content: center;
    border-radius: 30px;
}

.logo-nav a h1 {
    font-size: 30px;
    line-height: 48px;
    text-align: center;
    margin: 0 3%;
    font-weight: 800;
    height: 54px;
    width: 199px;
}

.nav-content {
    margin-right: 4%;
}

.nav-content a {
    color: #F1F6F5;
    font-size: 24px;
    margin: 24px;
}
.nav-content .btn-1 {
    margin: auto 30px;
}