#wizard-rendezVous {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0px 0px 8px #123e70;
  background-color: #F1F6F5;
  width: 1003px;
  height: 596px;
  max-width: 1920px;
  margin: 160px auto;
  border-radius: 20PX;
  position: relative;
}

#wizard-rendezVous h1 {
  font-size: 44px;
  margin: 22px auto;
  text-align: center;
}
/* ------------- */

  #wizard-rendezVous .steps ul {
    overflow: hidden;
    display: flex;
    box-shadow: 0px 0px 2px #123e70;
    border-radius: 5px;
    padding: 0;
  }

    #wizard-rendezVous .steps ul li div {
    color: #999;
    padding: 10px 0 15px 45px;
    position: relative;
    background-color: #F1F6F5;
    width: 200px;
  }

  #wizard-rendezVous .steps ul li div span {
    font-size: 13px;
    color: #F1F6F5;
  }
   #wizard-rendezVous .steps ul li:first-child div {
    width: 180px;
    padding-left: 15px;
  }
   #wizard-rendezVous .steps ul li div::before {
    content: " ";
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid #ddd;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 1;
    margin-left: 1px;
  }
   #wizard-rendezVous .steps ul li div::after {
    content: " ";
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid #f5f5f5;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;
  }
   #wizard-rendezVous .steps ul li.done div {
    border-color: #123e70 !important;
    color: #fff !important;
    background: #123e70 !important;
  }
   #wizard-rendezVous .steps ul li.done div::after {
    border-left: 30px solid #123e70;
  }
   #wizard-rendezVous .steps ul li.active div {
    border-color: #20a8d8 !important;
    color: #fff !important;
    background: #20a8d8 !important;
  }
   #wizard-rendezVous .steps ul li.active div::after {
    border-left: 30px solid #20a8d8;
  }
   
   #wizard-rendezVous .step-component {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
   #wizard-rendezVous .btn-component {
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }

/* ------------- */

#wizard-rendezVous #regForm{
  box-shadow: 0px 0px 2px #123e70;
  width: max-content;
}

.item-rendez-vous select, .item-rendez-vous input{
    width: 900px;
}

#regForm textarea {
    display: none;
}
.btn-form-rendez-vous{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
    bottom: 4px;
}

.btn-form-rendez-vous button {
    margin: 10px;
}