.heigh-top{
    height: 20px;
}
.Slide-service{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    margin: 3% 0;
}

.img-slide-service {
    /* margin:  auto; */
    display: flex;
    justify-content: center;
}

.img-slide-service img{
    width: 80%;
    border-radius: 20px;
    box-shadow: 0px 0px 8px #123e70;
}

.content-text-service{
    width: 800px;
    text-align: center;
}
.content-text-service .title-service{
    font-size: 62px;
    margin: 20px;
}
.text-service{
    font-size: 24px;
}
.barServiseSlide{
    background-color: #F1F6F5;
    height: 500px;
    position: absolute;
    top: 180px;
    width: 100%;
    z-index: -1;
    box-shadow: 0px 0px 11px 0.002px #123E70;
}
ul.list-service{
    font-size: 19px;

    margin:20px;
    text-align: justify;
}