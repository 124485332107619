ul {list-style-type: none;}
.month {
    padding: 25px;
    background: #123E70;
    text-align: center;
  }
  
  .month ul {
    margin: 0;
    padding: 0;
  }
  
  .month ul li {
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
  
  .month .prev {
    float: left;
    padding-top: 10px;
  }
  
  .month .next {
    float: right;
    padding-top: 10px;
  }
  
  .weekdays {
    margin: 0;
    padding: 10px 0;
    background-color: #ddd;
  }
  .slid-calendar {
    width: 932px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 0px 2px #123e70;
  }

  .weekdays li {
    display: inline-block;
    width: 13.6%;
    color: #666;
    text-align: center;
  }
  
  .days {
    padding: 10px 0;
    background: #eee;
    margin: 0;
  }
  
  .days li {
    list-style-type: none;
    display: inline-block;
    width: 13.6%;
    text-align: center;
    margin-bottom: 5px;
    height: 30px;
    font-size: 20px;
    color: #777;
  }
  
  .days li .active {
    padding: 5px;
    background: #123E70;
    color: white !important
  }
  
  /* Add media queries for smaller screens */
  @media screen and (max-width:720px) {
    .weekdays li, .days li {width: 13.1%;}
  }
  
  @media screen and (max-width: 420px) {
    .weekdays li, .days li {width: 12.5%;}
    .days li .active {padding: 2px;}
  }
  
  @media screen and (max-width: 290px) {
    .weekdays li, .days li {width: 12.2%;}
  }